<template lang="pug">
.select-filter.form-field.form-multiselect.filter(:class="filterClass")
  .select-filter__label(v-if="showSelectionLabel") {{ label }}
  multiselect.multiple-select-filter-field(
    v-model="currentFilter",
    track-by="value",
    label="label",
    :disabled="disabled",
    :multiple="multiple",
    :placeholder="placeholder",
    :show-labels="false",
    @search-change="searchFn",
    :options="filterOptions",
    :closeOnSelect="!multiple",
    :hideSelected="true",
    @open="multiselectHandler",
    open-direction="bottom"
  )
    span(slot="noResult")
      i {{ notifies.no_search_result }}
    span(slot="noOptions")
      i {{ notifies.no_options_list }}

    template(slot="afterList")
      div(v-if="optObject && optObject.count && hasNextPage()", style="text-align: center")
        div(v-observe-visibility="reachedEndOfList")
        span(style="padding: 10px") ...

  q-icon.cancel-select-filter(name="cancel", v-show="isResettable", @click.stop="resetFilter()")
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  components: {
    Multiselect,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectionLabel: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      multiple: this.parentData.data[0].multiple || false,
      value: this.parentData.data[0].value,
      reset: this.parentData.data[0].reset === undefined ? true : this.parentData.data[0].reset,
      name: this.parentData.data[0].name,
      staticData: this.parentData.data[0].data,

      options_params: this.parentData.data[0].options_params || {},
      options_path: this.parentData.data[0].options_path,
      watch: this.parentData.data[0].watch || {},

      options_data: this.parentData.options_data,

      optObject: {},
      options: [],
      filterOptions: [],

      nextPage: 2,
      pageSize: 20,
      loading: false,
      error: false,
      parent: null,
    };
  },

  computed: {
    label() {
      return this.parentData.data[0].label;
    },

    parentFilter() {
      if (this.watch && this.watch["parent"]) {
        return this.currentFilters[this.watch["parent"]];
      } else {
        return undefined;
      }
    },

    currentFilter: {
      get() {
        return this.currentFilters[this.name];
      },
      set(value) {
        this.$store.commit("updateFilter", { grid_name: this.grid, filter: this.name, value: value });
        if (!value || (this.multiple && !value.length))
          this.$store.commit("resetFilter", { grid_name: this.grid, filter: this.name });
      },
    },

    isResettable() {
      if (!this.currentFilter) {
        // if default/value is not set
        return false;
      }

      return this.reset;
    },

    showSelectionLabel() {
      return this.selectionLabel && this.currentFilter ? true : false;
    },

    filterClass() {
      return {
        "select-filter--selection-label": this.showSelectionLabel,
      };
    },

    placeholder() {
      return this.showSelectionLabel ? "" : this.label;
    },

    valueExists() {
      return !!this.currentFilter;
    },
  },

  watch: {
    parentFilter(newValue, oldValue) {
      if (this.watch && this.watch["parent"]) {
        if ((newValue && oldValue && newValue.value !== oldValue.value) || newValue) {
          this.options_params["infinite_scroll"] = {
            page: 1,
            per_page: this.pageSize,
          };

          this.nextPage = 2;

          let attr = Object.keys(this.options_params).reduce((result, key) => {
            result[key] = this.options_params[key];
            return result;
          }, {});

          this.parent = newValue && (newValue.value || newValue.map(v => v.value));

          attr[this.watch["parent"]] = this.parent;
          this.options = [];

          this.emitLoadOptions(attr, this.name);
        } else if (!newValue && oldValue) {
          this.resetFilter();
          this.options = [];
          this.parent = null;
          this.loadDefaultOptions();
        }
      }
    },
  },

  beforeMount() {
    this.loadDefaultOptions();
  },

  methods: {
    canCancel(val = this.currentFilter) {
      if (this.multiple) {
        return val && val.length > 0;
      } else {
        return val && val.value !== "";
      }
    },

    emitLoadOptions(params, filter_name) {
      const options = { params: params, filter_name: filter_name, static_data: this.staticData };

      // By default currentFilter will contain default value (like number) or be undefined
      // When value is set it's a plain object
      if (!_.isPlainObject(this.currentFilter)) {
        options["default_value"] = this.value;
      }

      this.$emit("load-options", options);
    },

    loadDefaultOptions(params = {}) {
      if (this.options_params) {
        if (this.options_params["infinite_scroll"]) {
          this.options_params["infinite_scroll"] = {
            page: 1,
            per_page: this.pageSize,
          };

          this.nextPage = 2;
        }
        params = this.options_params;
      }

      this.emitLoadOptions(params, this.name);
    },

    resetFilter() {
      this.$store.commit("resetFilter", { grid_name: this.grid, filter: this.name });
    },

    setOptionsData(data) {
      // КОСТЫЛЬ -- в этой компоненте частенько бывают запросы на вторую страницы пагинации, когда данных на ней нет.
      // В таких случаях бек возвращает пустую коллекцию. В некоторых случаях такой запрос удаляет уже выбранные значения.
      //
      // Пример -- фильтр "Услуга" (work_category_id) для новой страницы заявок для мвм (выбрать одно значение и потом
      // вбить в поисе одну букву, например "w", воспроизводится не каждый раз, но довольно часто).
      // Пока что не получилось найти корень проблемы (но он видимо связан с неправильным триггером
      // v-observe-visibility="reachedEndOfList")
      //
      // Поэтому сделана обертка, которая не дает перезаписать данные, когда мы на второй странице и пришли данные
      if (this.nextPage > 2 && data.opt_object.options.length === 0) {
        return;
      }

      this.optObject = data.opt_object;
      data.options.forEach(item => {
        let i = this.options.findIndex(opt => {
          return opt.value === item.value;
        });
        if (i < 0) {
          this.options.push(item);
        }
      });
      this.filterOptions = this.options;

      if (this.currentFilter) {
        if (this.multiple) {
          let val = this.currentFilter.map(el => el.value);

          if (val.length > 0) {
            val.forEach(id => {
              if (!this.options.map(el => el.value).includes(id)) {
                this.currentFilter = this.currentFilter.filter(el => el.value !== id);
              }
            });
          }
        } else {
          if (!this.options.map(el => el.value).includes(this.currentFilter.value)) {
            this.resetFilter();
          }
        }
      }
    },

    setLoading(val) {
      this.loading = val;
    },

    setError(val) {
      this.error = val;
    },

    reachedEndOfList(reached) {
      if (reached && !this.error && !this.loading) {
        this.loading = true;

        this.$nextTick(() => {
          this.options_params["infinite_scroll"] = {
            page: this.nextPage,
            per_page: this.pageSize,
          };

          let attr = Object.keys(this.options_params).reduce((result, key) => {
            result[key] = this.options_params[key];
            return result;
          }, {});

          if (this.parent) {
            attr[this.watch["parent"]] = this.parent;
          }

          this.nextPage++;
          this.emitLoadOptions(attr, this.name);
        });
      }
    },

    hasNextPage() {
      let lastPage = Math.ceil(this.optObject.count / this.pageSize);
      return this.nextPage <= lastPage;
    },

    searchFn(query) {
      let search_query = query.length > 0 ? query : null;
      this.options_params["search_query"] = search_query;

      this.options_params["infinite_scroll"] = {
        page: 1,
        per_page: this.pageSize,
      };

      this.nextPage = 2;

      this.options = [];
      this.emitLoadOptions(this.options_params, this.name);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/styles/filters/select";

.select-filter {
  cursor: pointer;

  &--selection-label {
    position: relative;

    .select-filter__label {
      position: absolute;
      left: 15px;
      font-size: 11px;
      color: var(--field-input-color);
    }

    :deep(.multiselect__tags) {
      position: relative;
      top: 5px;
      padding-top: 13px !important;

      .multiselect__tag {
        margin-bottom: 0;
        max-width: 150px;
      }
    }
  }
}
</style>
